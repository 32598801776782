import { initialState } from "./types"

const AdvancedFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INITIAL_FILTERS': {
            return initialState
        }
        case 'HANDLE_FILTERS': {
            const instanceAdvance = state[action.value.key]

            let filters = instanceAdvance.filters

            if (action.value && action.value.data.name) {
                const values = action.value.data.values

                if (values && values.length > 0) {
                    filters = {
                        ...filters,
                        [action.value?.data?.name]: action.value.data
                    }
                } else {
                    delete filters[action.value.data.name]
                }

                // state.filtersType = {
                //     ...state.filtersType,
                //     [action.value.name]: {
                //         ...state.filtersType[action.value.name],
                //         show: true
                //     }
                // }
            }

            return {
                ...state,
                [action.value.key]: {
                    ...instanceAdvance,
                    filters,
                    change: true
                }
            }
        }
        // case 'HANDLE_APPEND_FILTERS': {
        //     const instanceAdvance = state[action.key]

        //     let filters = instanceAdvance.filters

        //     if (action.value && action.value.name) {
        //         let values = action.value.values

        //         if (values && values.length > 0) {
        //             values = [
        //                 ...(filters[action.value.name]?.values || []),
        //                 ...values
        //             ]

        //             values = Array.from(new Set(values))

        //             filters = {
        //                 ...filters,
        //                 [action.value.name]: {
        //                     name: action.value.name,
        //                     operation: action.value.operation,
        //                     values
        //                 }
        //             }
        //         } else {
        //             delete filters[action.value.name]
        //         }

        //         instanceAdvance.filtersType = {
        //             ...state.filtersType,
        //             [action.value.name]: {
        //                 ...state.filtersType[action.value.name],
        //                 show: true
        //             }
        //         }
        //     }

        //     return {
        //         ...state,
        //         [action.key]: {
        //             ...instanceAdvance,
        //             filters,
        //             change: true
        //         }
        //     }
        // }
        case 'HANDLE_ADD_FILTER': {
            if (action.value.data) {
                const instanceAdvance = state[action.value.key]

                return {
                    ...state,
                    [action.value.key]: {
                        ...instanceAdvance,
                        filtersType: {
                            ...instanceAdvance.filtersType,
                            [action.value.data]: {
                                ...instanceAdvance.filtersType[action.value.data],
                                show: true
                            }
                        }
                    }
                }
            }
            return state
        }
        case 'HANDLE_REMOVE_FILTER': {
            if (action.value.filterName) {
                const instanceAdvance = state[action.value.key]

                const updatedFilters = { ...instanceAdvance.filters }
                const updatedFiltersType = { ...instanceAdvance.filtersType }

                if (updatedFilters[action.value.filterName]?.values?.length) {
                    instanceAdvance.change = true
                }

                delete updatedFilters[action.value.filterName]

                updatedFiltersType[action.value.filterName] = {
                    ...updatedFiltersType[action.value.filterName],
                    show: false
                }

                return {
                    ...state,
                    [action.value.key]: {
                        filters: updatedFilters,
                        filtersType: updatedFiltersType,
                        change: true
                    }
                }
            }
            return state
        }
        case 'HANDLE_RESET_FILTERS': {
            const instanceAdvance = initialState[action.value.key]
            return {
                ...state,
                [action.value.key]: {
                    ...instanceAdvance,
                    change: true
                }
            }


            return state
        }
        case 'HANDLE_CHANGE': {
            const instanceAdvance = state[action.value.key]
            return {
                ...state,
                [action.value.key]: {
                    ...instanceAdvance,
                    change: action.value.data
                }
            }
        }
        default:
            return state

    }
}
export default AdvancedFilterReducer